<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M17.5029 10L13.3345 5.8316" stroke="#FC5A5A" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M13.3345 14.1684L17.5029 10" stroke="#FC5A5A" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M17.503 10H8.33252" stroke="#FC5A5A" stroke-linecap="round" stroke-linejoin="round" />
		<path
			d="M8.33235 17.5031H6.38709C4.23842 17.5031 2.49658 15.7613 2.49658 13.6126V6.66529C2.49658 4.51662 4.23842 2.77478 6.38709 2.77478H8.33235"
			stroke="#FC5A5A"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'logout',
};
</script>

<style scoped></style>
