<template>
	<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="0.333374" width="32" height="32" rx="8" fill="#F3F9FF" />
		<path
			d="M23.8333 20.1504L16.325 23.5004"
			stroke="#1E96FC"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M16.325 23.5004L8.83337 20.1504"
			stroke="#1E96FC"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path d="M23.8333 16L16.325 19.35" stroke="#1E96FC" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M16.325 19.35L8.83337 16" stroke="#1E96FC" stroke-linecap="round" stroke-linejoin="round" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M8.83337 11.8533L16.3225 15.2075L23.8334 11.8533L16.3442 8.5L8.83337 11.8533Z"
			stroke="#1E96FC"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'LBOB3',
};
</script>

<style scoped></style>
