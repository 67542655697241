<template>
	<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M3.9375 4.8125H1.75V2.625" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
		<path
			d="M12.25 7C12.25 4.10025 9.89973 1.75 6.99998 1.75C4.88248 1.75 3.06189 3.0065 2.23181 4.8125"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path d="M10.0625 9.1875H12.25V11.375" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
		<path
			d="M1.75 7C1.75 9.89975 4.10025 12.25 7 12.25C9.1175 12.25 10.9381 10.9935 11.7682 9.1875"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'refreshData',
};
</script>

<style scoped></style>
