<template>
	<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M31.5 31.5H7.5C5.8425 31.5 4.5 30.1575 4.5 28.5V4.5"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M31.5 9L24.603 17.277C24.234 17.7195 23.646 17.9115 23.0865 17.772L18.9135 16.728C18.3555 16.5885 17.766 16.7805 17.397 17.223L10.5 25.5"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'Economics',
};
</script>

<style scoped></style>
