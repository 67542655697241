<template>
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width="24" height="24" rx="12" fill="#D2EAFE" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12 21C7.029 21 3 16.971 3 12C3 7.029 7.029 3 12 3C16.971 3 21 7.029 21 12C21 16.971 16.971 21 12 21Z"
			fill="#1E96FC"
		/>
		<path
			d="M12 12V17"
			stroke="white"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M11.999 8C11.861 8 11.749 8.112 11.75 8.25C11.75 8.388 11.862 8.5 12 8.5C12.138 8.5 12.25 8.388 12.25 8.25C12.25 8.112 12.138 8 11.999 8"
			stroke="white"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>

<script>
export default {
	name: 'snackInfo',
};
</script>

<style scoped></style>