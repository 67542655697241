<template>
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12 21C7.029 21 3 16.971 3 12C3 7.029 7.029 3 12 3C16.971 3 21 7.029 21 12C21 16.971 16.971 21 12 21Z"
			fill="#24C182"
			fill-opacity="0.1" />
		<path d="M16 10L11 15L8 12" stroke="#24C182" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'success',
};
</script>

<style scoped></style>
