<template>
	<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M21 10.5L16.0605 5.56051C15.474 4.97401 14.5245 4.97401 13.9395 5.56051L9 10.5"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M18 7.50001L19.9395 5.56051C20.526 4.97401 21.4755 4.97401 22.0605 5.56051L27 10.5"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M31.5 18H26.1285C24.9525 18 24 18.9525 24 20.1285V21.8715C24 23.0475 24.9525 24 26.1285 24H31.5"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M28.5 31.5H7.5C5.8425 31.5 4.5 30.1575 4.5 28.5V13.5C4.5 11.8425 5.8425 10.5 7.5 10.5H28.5C30.1575 10.5 31.5 11.8425 31.5 13.5V28.5C31.5 30.1575 30.1575 31.5 28.5 31.5Z"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'Budgets',
};
</script>

<style scoped></style>
