<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M2.25 15.75V14.25C2.25 13.4544 2.56607 12.6913 3.12868 12.1287C3.69129 11.5661 4.45435 11.25 5.25 11.25H8.25C9.04565 11.25 9.80871 11.5661 10.3713 12.1287C10.9339 12.6913 11.25 13.4544 11.25 14.25V15.75M12 2.3475C12.6453 2.51273 13.2173 2.88803 13.6257 3.41424C14.0342 3.94044 14.2559 4.58763 14.2559 5.25375C14.2559 5.91988 14.0342 6.56706 13.6257 7.09327C13.2173 7.61948 12.6453 7.99478 12 8.16M15.75 15.75V14.25C15.7462 13.5879 15.5234 12.9456 15.1165 12.4233C14.7095 11.901 14.1411 11.528 13.5 11.3625M3.75 5.25C3.75 6.04565 4.06607 6.80871 4.62868 7.37132C5.19129 7.93393 5.95435 8.25 6.75 8.25C7.54565 8.25 8.30871 7.93393 8.87132 7.37132C9.43393 6.80871 9.75 6.04565 9.75 5.25C9.75 4.45435 9.43393 3.69129 8.87132 3.12868C8.30871 2.56607 7.54565 2.25 6.75 2.25C5.95435 2.25 5.19129 2.56607 4.62868 3.12868C4.06607 3.69129 3.75 4.45435 3.75 5.25Z" stroke="#A9A9AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
</template>

<script>
export default {
	name: 'menuTeam',
};
</script>

<style scoped></style>
