<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M11.6674 17.5031H13.3348C15.6369 17.5031 17.5032 15.6368 17.5032 13.3347V6.66523C17.5032 4.36308 15.6369 2.49683 13.3348 2.49683H6.66532C4.36318 2.49683 2.49692 4.36308 2.49692 6.66523V13.3347C2.49692 15.6368 4.36318 17.5031 6.66532 17.5031H11.6674Z"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M12.5011 17.5031V16.6694C12.5011 14.3672 14.3674 12.501 16.6695 12.501H17.5032"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path d="M9.99988 7.5V12.5" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M12.4999 10H7.49988" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'PAGE_CREATED',
};
</script>

<style scoped></style>
