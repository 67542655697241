<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M8.33337 6.66675L5.83337 4.16675L3.33337 6.66675"
			stroke="#FF974A"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path d="M5.83341 15.8334V4.16675" stroke="#FF974A" stroke-linecap="round" stroke-linejoin="round" />
		<path
			d="M11.6667 13.3335L14.1667 15.8335L16.6667 13.3335"
			stroke="#FF974A"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path d="M14.1667 4.16675V15.8334" stroke="#FF974A" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'profilerPosition',
};
</script>

<style scoped></style>
