<template>
	<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M8.40239 10.8308H10.6982C12.7482 10.8308 14.4932 9.33917 14.8132 7.31417V7.31417C15.2116 4.78667 13.2582 2.5 10.6982 2.5H6.25656C5.84656 2.5 5.49739 2.79833 5.43323 3.20333L3.59323 14.87C3.51406 15.3758 3.90489 15.8333 4.41656 15.8333H6.78823L7.44823 11.6467C7.52239 11.1767 7.92739 10.8308 8.40239 10.8308Z"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M14.8056 5.96924C16.2531 6.79424 17.1465 8.45341 16.8648 10.2376C16.5456 12.2626 14.7998 13.7542 12.7498 13.7542H12.7673H10.5765L10.0965 16.7959C10.0331 17.2017 9.68312 17.5001 9.27312 17.4992L7.01312 17.4976C6.75729 17.4976 6.56229 17.2684 6.60146 17.0159L6.78812 15.8334"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'NewPayPal',
};
</script>

<style scoped></style>
