<template>
	<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle
			cx="10.1664"
			cy="10.1663"
			r="6.2526"
			stroke="#1E96FC"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M18.0863 18.0863L14.5873 14.5873"
			stroke="#1E96FC"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path d="M7.31598 8.62399H12.97" stroke="#1E96FC" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M7.31598 11.7086H11.3185" stroke="#1E96FC" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'keywords',
};
</script>

<style scoped></style>
