export default function formatDate(date, type) {
	if (type === 'MY') {
		return new Date(date)
			.toLocaleDateString('en', { year: '2-digit', month: '2-digit' })
			.replace('/', ' / ');
	} else if (type === 'MD Time') {
		return new Date(date).toLocaleString('en-US', {
			month: 'long',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			hour12: true,
		});
	} else if (type === 'standard') {
		return new Date(date)
			.toLocaleString('en-US', {
				month: 'short',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
				hour12: false,
			})
			.replace(',', ' at');
	} else {
		const dateObj = new Date(date);
		const month = String(dateObj.getMonth() + 1).padStart(2, '0');
		const day = String(dateObj.getDate()).padStart(2, '0');
		const year = dateObj.getFullYear();
		const shorYear = String(dateObj.getFullYear()).slice(-2);
		const output = `${day}.${month}.${year}`;
		const shorYearDate = `${day}.${month}.${shorYear}`;
		const isoOutput = `${year}-${month}-${day}`;
		if (type === 'ISO') {
			return isoOutput;
		} else if (type === 'shortY') {
			return shorYearDate;
		} else {
			return date ? output : '';
		}
	}
}
