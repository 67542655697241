<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M16.6694 6.66547H13.3347C12.8743 6.66547 12.501 6.29222 12.501 5.83179V2.49707"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M4.99789 6.66547V4.16443C4.99789 3.24357 5.7444 2.49707 6.66526 2.49707H12.6444C13.0866 2.49707 13.5106 2.67289 13.8232 2.98561L16.1809 5.34326C16.4936 5.65587 16.6694 6.0799 16.6694 6.52208V15.836C16.6694 16.7568 15.9229 17.5033 15.0021 17.5033H6.66526C5.7444 17.5033 4.99789 16.7568 4.99789 15.836V15.0023"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M7.97165 12.2929L5.47061 9.375"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M5.47061 12.2929L7.97165 9.375"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<rect
			x="2.49686"
			y="6.66602"
			width="8.33681"
			height="8.33681"
			rx="3"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'UploadData',
};
</script>

<style scoped></style>
