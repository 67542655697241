<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M11.1942 2.55583C14.74 3.15167 17.4442 6.22833 17.4442 9.94333C17.4442 14.0858 14.0867 17.4433 9.94417 17.4433C6.22917 17.4433 3.1525 14.7392 2.55667 11.1933"
			stroke="#1E96FC"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M2.55584 8.69417C2.63501 8.22334 2.75751 7.76917 2.92001 7.3325"
			stroke="#1E96FC"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M5.16418 4.16501C4.80001 4.46584 4.46584 4.80001 4.16501 5.16418"
			stroke="#1E96FC"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M8.69419 2.55584C8.22335 2.635 7.76835 2.75834 7.33252 2.92084"
			stroke="#1E96FC"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M10.3259 5.83334V10.3258H6.66669"
			stroke="#1E96FC"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'inprogress',
};
</script>

<style scoped></style>
