<template>
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12 21C7.029 21 3 16.971 3 12C3 7.029 7.029 3 12 3C16.971 3 21 7.029 21 12C21 16.971 16.971 21 12 21Z"
			fill="#FC5A5A"
			fill-opacity="0.1" />
		<path
			d="M14.8299 9.16992L9.16992 14.8299"
			stroke="#FC5A5A"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M14.8299 14.8299L9.16992 9.16992"
			stroke="#FC5A5A"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'errorIcon',
};
</script>

<style scoped></style>
