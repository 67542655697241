<template>
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="32" height="32" rx="8" fill="#F1FCF8" />
		<path
			d="M9.75 16.625L8.5 17.875L7.25 16.625"
			stroke="#24C182"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M22.25 15.375L23.5 14.125L24.75 15.375"
			stroke="#24C182"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M22.1425 11.7042C20.7867 9.76833 18.5433 8.5 16 8.5C11.8575 8.5 8.5 11.8575 8.5 16C8.5 16.5783 8.57167 17.1383 8.69583 17.6792"
			stroke="#24C182"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M9.85748 20.2958C11.2133 22.2317 13.4566 23.5 16 23.5C20.1425 23.5 23.5 20.1425 23.5 16C23.5 15.4217 23.4283 14.8617 23.3041 14.3208"
			stroke="#24C182"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M18.5 14.8775V18.5925C18.5 19.0017 18.1683 19.3333 17.7592 19.3333H14.2408C13.8317 19.3333 13.5 19.0017 13.5 18.5925V13.4075C13.5 12.9983 13.8317 12.6667 14.2408 12.6667H16.3342C16.535 12.6667 16.7267 12.7483 16.8658 12.8917L18.2908 14.3617C18.425 14.5008 18.5 14.685 18.5 14.8775V14.8775Z"
			stroke="#24C182"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'CPUpdate',
};
</script>

<style scoped></style>
