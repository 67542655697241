<template>
	<v-app>
		<router-view />
	</v-app>
</template>
<script>
export default {
	name: 'EmptyLayout',
};
</script>

<style scoped></style>
