<template>
	<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="Group">
			<path
				id="Path"
				d="M13.3901 24.0025L12.6398 30.005"
				stroke="#8591AE"
				stroke-linecap="round"
				stroke-linejoin="round" />
			<path
				id="Path_2"
				d="M30.6473 11.9814V8.1379C30.6473 6.12564 29.016 4.49438 27.0038 4.49438H8.1379C6.12564 4.49438 4.49438 6.12564 4.49438 8.1379V20.359C4.49438 22.3713 6.12564 24.0025 8.1379 24.0025H17.2814"
				stroke="#8591AE"
				stroke-linecap="round"
				stroke-linejoin="round" />
			<g id="Group 30">
				<g id="Group 31">
					<path
						id="Path_3"
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M32.1058 31.4997H20.3032C19.8017 31.4997 19.3953 31.0932 19.3953 30.5918V29.8546C19.4012 27.7571 21.1001 26.0583 23.1975 26.0523H29.2114C31.3089 26.0583 33.0077 27.7571 33.0137 29.8546V30.5918C33.0137 31.0932 32.6072 31.4997 32.1058 31.4997Z"
						stroke="#8591AE"
						stroke-linecap="round"
						stroke-linejoin="round" />
					<path
						id="Path_4"
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M26.2042 22.2632C24.1999 22.2591 22.5778 20.6322 22.5798 18.6279C22.5818 16.6237 24.2072 15 26.2114 15C28.2157 15 29.841 16.6237 29.843 18.6279C29.845 20.6322 28.2229 22.2591 26.2187 22.2632H26.2042Z"
						stroke="#8591AE"
						stroke-linecap="round"
						stroke-linejoin="round" />
				</g>
			</g>
			<path
				id="Path_5"
				d="M10.3887 30.005H15.0234"
				stroke="#8591AE"
				stroke-linecap="round"
				stroke-linejoin="round" />
		</g>
	</svg>
</template>

<script>
export default {
	name: 'UsersActivity',
};
</script>

<style scoped></style>
