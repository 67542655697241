<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
		<path
			d="M17.5005 14.1499L9.99219 17.4999"
			stroke="#24C182"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path d="M9.99167 17.4999L2.5 14.1499" stroke="#24C182" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M17.5005 10L9.99219 13.35" stroke="#24C182" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M9.99167 13.35L2.5 10" stroke="#24C182" stroke-linecap="round" stroke-linejoin="round" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M2.5 5.85333L9.98917 9.2075L17.5 5.85333L10.0108 2.5L2.5 5.85333Z"
			stroke="#24C182"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'PBNStack',
};
</script>

<style scoped></style>
