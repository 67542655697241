<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M3.33325 9.92168V6.74251C3.33325 5.70251 3.97742 4.77084 4.94992 4.40334L11.9108 1.77501C13.0016 1.36418 14.1666 2.16918 14.1666 3.33501V6.12668"
			stroke="#3274AC"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M14.3741 11.7508C14.2591 11.7517 14.1666 11.845 14.1666 11.96C14.1666 12.075 14.2599 12.1683 14.3749 12.1675C14.4899 12.1675 14.5833 12.0742 14.5833 11.9592C14.5833 11.8442 14.4899 11.7508 14.3741 11.7508"
			stroke="#3274AC"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M8.88492 17.5H14.9999C16.3749 17.5 17.4999 16.375 17.4999 15V8.62582C17.4999 7.24499 16.3808 6.12582 14.9999 6.12582H4.99992C4.07909 6.12582 3.33325 6.87166 3.33325 7.79249V10.2142"
			stroke="#3274AC"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M6.25008 18.3333C3.71925 18.3333 1.66675 16.2808 1.66675 13.75C1.66675 11.2192 3.71925 9.16666 6.25008 9.16666C8.78175 9.16666 10.8334 11.2192 10.8334 13.75C10.8334 16.2808 8.78175 18.3333 6.25008 18.3333"
			stroke="#3274AC"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path d="M8.07761 13.75H4.42261" stroke="#3274AC" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'spending',
};
</script>

<style scoped></style>
