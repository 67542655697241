<template>
	<component :is="layout">
		<router-view />
	</component>
</template>
<script>
import EmptyLayout from '@/layouts/EmptyLayout';
import MainLayout from '@/layouts/MainLayout';
export default {
	name: 'App',
	components: {MainLayout, EmptyLayout},
	computed: {
		layout() {
			return this.$route.meta.layout || 'EmptyLayout';
		},
	},
};
</script>
