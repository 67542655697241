<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M9.75 3.75H15.75M9.75 6.75H13.5M9.75 11.25H15.75M9.75 14.25H13.5M2.25 3.75C2.25 3.55109 2.32902 3.36032 2.46967 3.21967C2.61032 3.07902 2.80109 3 3 3H6C6.19891 3 6.38968 3.07902 6.53033 3.21967C6.67098 3.36032 6.75 3.55109 6.75 3.75V6.75C6.75 6.94891 6.67098 7.13968 6.53033 7.28033C6.38968 7.42098 6.19891 7.5 6 7.5H3C2.80109 7.5 2.61032 7.42098 2.46967 7.28033C2.32902 7.13968 2.25 6.94891 2.25 6.75V3.75ZM2.25 11.25C2.25 11.0511 2.32902 10.8603 2.46967 10.7197C2.61032 10.579 2.80109 10.5 3 10.5H6C6.19891 10.5 6.38968 10.579 6.53033 10.7197C6.67098 10.8603 6.75 11.0511 6.75 11.25V14.25C6.75 14.4489 6.67098 14.6397 6.53033 14.7803C6.38968 14.921 6.19891 15 6 15H3C2.80109 15 2.61032 14.921 2.46967 14.7803C2.32902 14.6397 2.25 14.4489 2.25 14.25V11.25Z" stroke="#A9A9AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
</template>
<script>
export default {
	name: 'menuAffiliateMarketing',
};
</script>
<style scoped></style>
