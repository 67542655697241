<template>
	<span :class="customClass" :style="{'background' : computedBackground}">
		{{ computedInitials }}
	</span>
</template>

<script>
export default {
	name: 'AvatarsItem',
	props: ['item', 'customClass'],
	computed: {
		computedBackground() {
			return this.item.team && this.item.team.defaultColor ? this.item.team.defaultColor : '#5b5b5b';
		},
		computedInitials() {
			return this.item.initials ? this.item.initials : '';
		},
	},
};
</script>

<style lang="scss" scoped></style>
