<template>
	<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M23.7954 6.57955V29.0795C23.7954 31.5972 25.5323 33 27.375 33C29.0795 33 30.9545 31.8068 30.9545 29.0795V6.75C30.9545 4.44205 29.25 3 27.375 3C25.5 3 23.7954 4.59034 23.7954 6.57955Z"
			stroke="#8591AE" />
		<path
			d="M14.4204 18.0001V29.0797C14.4204 31.5973 16.1573 33.0001 18 33.0001C19.7045 33.0001 21.5795 31.807 21.5795 29.0797V18.1706C21.5795 15.8626 19.875 14.4206 18 14.4206C16.125 14.4206 14.4204 16.0109 14.4204 18.0001Z"
			stroke="#8591AE" />
		<path
			d="M8.62496 33C10.6019 33 12.2045 31.3974 12.2045 29.4204C12.2045 27.4435 10.6019 25.8409 8.62496 25.8409C6.64803 25.8409 5.04541 27.4435 5.04541 29.4204C5.04541 31.3974 6.64803 33 8.62496 33Z"
			stroke="#8591AE" />
	</svg>
</template>

<script>
export default {
	name: 'Ga',
};
</script>

<style scoped></style>
