<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M5.4668 10L7.1668 11.7" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M7.1668 8.30005L5.4668 10" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M12.8359 11.7017L14.5368 10L12.8359 8.29834" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M10.7751 7.08337L9.2251 12.9167" stroke-linecap="round" stroke-linejoin="round" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M15 17.5H5C3.61917 17.5 2.5 16.3808 2.5 15V5C2.5 3.61917 3.61917 2.5 5 2.5H15C16.3808 2.5 17.5 3.61917 17.5 5V15C17.5 16.3808 16.3808 17.5 15 17.5Z"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'PAGE_META_CHANGED',
};
</script>

<style scoped></style>
