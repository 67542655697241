<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M4.16667 13.3333H3.70333C3.03917 13.3333 2.5 12.7942 2.5 12.13V3.70333C2.5 3.03917 3.03917 2.5 3.70333 2.5H12.1292C12.7942 2.5 13.3333 3.03917 13.3333 3.70333V4.16667"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M16.2965 17.4998H7.86984C7.20567 17.4998 6.6665 16.9607 6.6665 16.2965V7.86984C6.6665 7.20567 7.20567 6.6665 7.86984 6.6665H16.2957C16.9607 6.6665 17.4998 7.20567 17.4998 7.86984V16.2957C17.4998 16.9607 16.9607 17.4998 16.2965 17.4998Z" />
	</svg>
</template>

<script>
export default {
	name: 'CopyIcon',
};
</script>

<style scoped></style>
