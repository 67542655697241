<template>
	<svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M27.7959 8.45406C33.068 13.7261 33.068 22.2739 27.7959 27.5459C22.5238 32.818 13.9761 32.818 8.70406 27.5459C3.43198 22.2738 3.43198 13.7261 8.70406 8.45406C13.9761 3.18198 22.5239 3.18198 27.7959 8.45406"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path d="M12.0635 16.1099H22.6677" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
		<path
			d="M19.0791 11.3853H17.7391C15.431 11.3853 13.9746 13.0711 13.9746 15.1498V16.4897V24.4613"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M22.668 16.1099L22.668 24.4614"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M22.961 11.4833C22.961 11.7006 22.7854 11.8768 22.5687 11.8768C22.3521 11.8768 22.1764 11.7006 22.1764 11.4833C22.1764 11.266 22.3521 11.0898 22.5687 11.0898"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M22.5689 11.0903C22.7855 11.0903 22.9611 11.2664 22.9611 11.4838"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'Fiverr',
};
</script>

<style scoped></style>
