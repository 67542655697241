<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M15.0021 8.33235V6.52159C15.0021 6.07974 14.8262 5.6554 14.5135 5.34277L12.1559 2.98512C11.8432 2.67249 11.4189 2.49658 10.9771 2.49658H4.9979C4.07668 2.49658 3.33054 3.24273 3.33054 4.16394V15.8355C3.33054 16.7567 4.07668 17.5028 4.9979 17.5028H8.33262"
			stroke="#8F20EF"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M15.0021 6.66498H11.6673C11.2071 6.66498 10.8336 6.2915 10.8336 5.8313V2.49658"
			stroke="#8F20EF"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M13.6609 13.3188V14.7838L14.8126 15.4863"
			stroke="#8F20EF"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M13.7501 18.333C11.6792 18.333 10.0001 16.6547 10.0001 14.583C10.0001 12.5547 11.7251 10.8305 13.7534 10.833C15.8234 10.8347 17.5001 12.513 17.5001 14.583C17.5001 16.6538 15.8217 18.333 13.7501 18.333"
			stroke="#8F20EF" />
	</svg>
</template>

<script>
export default {
	name: 'PAGE_CONTENT_PLANNED',
};
</script>

<style scoped></style>
