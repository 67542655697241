<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M6 5.25V3.75C6 3.35218 6.15804 2.97064 6.43934 2.68934C6.72064 2.40804 7.10218 2.25 7.5 2.25H10.5C10.8978 2.25 11.2794 2.40804 11.5607 2.68934C11.842 2.97064 12 3.35218 12 3.75V5.25M2.25 9.75C4.34369 10.805 6.65552 11.3546 9 11.3546C11.3445 11.3546 13.6563 10.805 15.75 9.75M2.25 6.75C2.25 6.35218 2.40804 5.97064 2.68934 5.68934C2.97064 5.40804 3.35218 5.25 3.75 5.25H14.25C14.6478 5.25 15.0294 5.40804 15.3107 5.68934C15.592 5.97064 15.75 6.35218 15.75 6.75V13.5C15.75 13.8978 15.592 14.2794 15.3107 14.5607C15.0294 14.842 14.6478 15 14.25 15H3.75C3.35218 15 2.97064 14.842 2.68934 14.5607C2.40804 14.2794 2.25 13.8978 2.25 13.5V6.75Z" stroke="#A9A9AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
</template>

<script>
export default {
	name: 'management',
};
</script>

<style scoped></style>
