<template>
	<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M17.1163 8.55901L17.734 7.94125C18.2434 7.43216 18.5211 6.736 18.5018 6.01609C18.4826 5.29617 18.1682 4.61583 17.6323 4.13467C16.5341 3.21596 14.9121 3.30411 13.9199 4.33642L13.4072 4.84996"
			stroke="#1E96FC"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M11.5525 6.7049L10.9347 7.32265C10.4254 7.83174 10.1477 8.5279 10.167 9.24782C10.1862 9.96774 10.5006 10.6481 11.0365 11.1292C12.1348 12.0481 13.7571 11.9596 14.7488 10.9267L15.2615 10.4139"
			stroke="#1E96FC"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M18.5028 11V15.1684C18.5028 17.0101 17.0098 18.5031 15.1681 18.5031H6.8313C4.98959 18.5031 3.49658 17.0101 3.49658 15.1684V6.83161C3.49658 4.98989 4.98959 3.49689 6.8313 3.49689H10.9997"
			stroke="#1E96FC"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M15.8792 6.0863L12.7896 9.17676"
			stroke="#1E96FC"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'brefUrlStart',
};
</script>

<style scoped></style>
