<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M9.84183 6.50004H5.8335" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M7.84147 5.83333V6.5" stroke-linecap="round" stroke-linejoin="round" />
		<path
			d="M8.841 6.50171C8.841 8.22504 7.49433 9.62088 5.8335 9.62088"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M9.84433 9.62086C9.1235 9.62086 8.4785 9.23669 8.021 8.63086"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M15 17.5H5C3.61917 17.5 2.5 16.3808 2.5 15V5C2.5 3.61917 3.61917 2.5 5 2.5H15C16.3808 2.5 17.5 3.61917 17.5 5V15C17.5 16.3808 16.3808 17.5 15 17.5Z"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path d="M12.2727 10.3792L10.3794 14.1667" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M14.1666 14.1667L12.2725 10.3792" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M10.7134 13.4983H13.8325" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'NEW_PAGE_TRANSLATE',
};
</script>

<style scoped></style>
