<template>
	<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M28.47 10.47L31.5 13.5" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
		<path
			d="M28.4694 4.28155C30.1782 5.9903 30.1782 8.7607 28.4694 10.4694C26.7607 12.1782 23.9903 12.1782 22.2816 10.4694C20.5728 8.7607 20.5728 5.9903 22.2816 4.28155C23.9903 2.57281 26.7607 2.57281 28.4694 4.28155"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M16.5 11.25H6C5.172 11.25 4.5 10.578 4.5 9.75V6C4.5 5.172 5.172 4.5 6 4.5H16.5"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M7.875 21.75H5.625C5.004 21.75 4.5 21.246 4.5 20.625V18.375C4.5 17.754 5.004 17.25 5.625 17.25H7.875C8.496 17.25 9 17.754 9 18.375V20.625C9 21.246 8.496 21.75 7.875 21.75Z"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path d="M13.833 19.5H31.5" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M7.875 31.5H5.625C5.004 31.5 4.5 30.996 4.5 30.375V28.125C4.5 27.504 5.004 27 5.625 27H7.875C8.496 27 9 27.504 9 28.125V30.375C9 30.996 8.496 31.5 7.875 31.5Z"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path d="M13.833 29.25H31.5" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'URLProfiler',
};
</script>

<style scoped></style>
