<template>
	<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M17.7 10.1704C17.7 9.63862 17.6523 9.12726 17.5636 8.63635H10.5V11.5375H14.5364C14.3625 12.475 13.8341 13.2693 13.0398 13.8011V15.6829H15.4636C16.8818 14.3773 17.7 12.4545 17.7 10.1704Z"
			fill="#4285F4" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M10.5 17.5C12.525 17.5 14.2227 16.8284 15.4636 15.6829L13.0398 13.8011C12.3682 14.2511 11.5091 14.517 10.5 14.517C8.54659 14.517 6.89318 13.1977 6.30341 11.425H3.79773V13.3682C5.03182 15.8193 7.56818 17.5 10.5 17.5Z"
			fill="#34A853" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M6.30341 11.425C6.15341 10.975 6.06818 10.4943 6.06818 10C6.06818 9.5057 6.15341 9.02502 6.30341 8.57502V6.63184H3.79773C3.28977 7.64434 3 8.78979 3 10C3 11.2102 3.28977 12.3557 3.79773 13.3682L6.30341 11.425Z"
			fill="#FBBC05" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M10.5 5.48295C11.6011 5.48295 12.5898 5.86136 13.367 6.60455L15.5182 4.45341C14.2193 3.24318 12.5216 2.5 10.5 2.5C7.56818 2.5 5.03182 4.18068 3.79773 6.63182L6.30341 8.575C6.89318 6.80227 8.54659 5.48295 10.5 5.48295Z"
			fill="#EA4335" />
	</svg>
</template>

<script>
export default {
	name: 'googleLogo',
};
</script>

<style scoped></style>
