<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M15.3453 5.345L12.9887 2.98833C12.6762 2.67583 12.252 2.5 11.8103 2.5H5.83366C4.91283 2.5 4.16699 3.24583 4.16699 4.16667V15.8333C4.16699 16.7542 4.91283 17.5 5.83366 17.5H14.167C15.0878 17.5 15.8337 16.7542 15.8337 15.8333V6.52333C15.8337 6.08167 15.6578 5.6575 15.3453 5.345V5.345Z"
			stroke="#8F20EF"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M15.8337 6.66667H12.5003C12.0403 6.66667 11.667 6.29333 11.667 5.83333V2.5"
			stroke="#8F20EF"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'Upload',
};
</script>

<style scoped></style>
