<template>
	<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M21.33 3.50251C26.247 4.24201 30.135 8.12851 30.873 13.0455C31.008 13.944 30.285 14.7495 29.376 14.7495H21.1245C20.2965 14.7495 19.6245 14.0775 19.6245 13.2495V4.99801C19.6245 4.08901 20.4315 3.36751 21.33 3.50251V3.50251Z"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M28.7761 26.3835C29.8546 24.927 30.5926 23.2035 30.8731 21.33C31.0081 20.4315 30.2851 19.626 29.3761 19.626H23.2456C21.9091 19.626 21.2401 21.2415 22.1851 22.1865L26.5216 26.523C27.1606 27.1605 28.2376 27.1095 28.7761 26.3835V26.3835Z"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M13.0636 9.99898C6.86406 10.92 2.29656 16.8435 3.59706 23.532C4.46256 27.9825 8.05957 31.563 12.5131 32.4105C15.8896 33.0525 19.0666 32.202 21.5011 30.4035C22.2301 29.865 22.2856 28.785 21.6451 28.1445L15.1891 21.6885C14.9071 21.4065 14.7496 21.0255 14.7496 20.628V11.493C14.7496 10.5915 13.9561 9.86698 13.0636 9.99898V9.99898Z"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'Summary',
};
</script>

<style scoped></style>
