<template>
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="32" height="32" rx="8" fill="#FFF8F2" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M23.5 16V16C23.5 20.1425 20.1425 23.5 16 23.5V23.5C11.8575 23.5 8.5 20.1425 8.5 16V16C8.5 11.8575 11.8575 8.5 16 8.5V8.5C20.1425 8.5 23.5 11.8575 23.5 16Z"
			stroke="#FF974A"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path d="M16 12.667V19.3337" stroke="#FF974A" stroke-linecap="round" stroke-linejoin="round" />
		<path
			d="M13.5 15.167L16 12.667L18.5 15.167"
			stroke="#FF974A"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'HeatmapRD',
};
</script>
