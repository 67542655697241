<template>
	<svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M28.2959 8.45406C33.568 13.7261 33.568 22.2739 28.2959 27.5459C23.0238 32.818 14.4761 32.818 9.20406 27.5459C3.93198 22.2738 3.93198 13.7261 9.20406 8.45406C14.4761 3.18198 23.0239 3.18198 28.2959 8.45406"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12 17.0617L18.75 18.8687L25.5 17.0617L18.75 9L12 17.0617Z"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M13.4551 20.6758L18.7505 27.0001L24.0458 20.6758L18.7505 22.0933L13.4551 20.6758Z"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'Eth',
};
</script>

<style scoped></style>
