<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M16.4817 8.70441C17.9133 7.27274 17.9133 4.95107 16.4817 3.51857C15.05 2.08691 12.7283 2.08691 11.2958 3.51857L9.35165 5.46274"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path d="M7.40833 12.5915L12.5917 7.4082" stroke-linecap="round" stroke-linejoin="round" />
		<path
			d="M5.4625 9.35156L3.51833 11.2957C2.08666 12.7274 2.08666 15.0491 3.51833 16.4816C4.95 17.9132 7.27166 17.9132 8.70416 16.4816"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path d="M14.4942 13.3193V14.7843L15.6459 15.4868" stroke-linecap="round" stroke-linejoin="round" />
		<path
			d="M14.5833 18.3335C12.5125 18.3335 10.8333 16.6552 10.8333 14.5835C10.8333 12.5552 12.5583 10.831 14.5867 10.8335C16.6567 10.8352 18.3333 12.5135 18.3333 14.5835C18.3333 16.6543 16.655 18.3335 14.5833 18.3335" />
	</svg>
</template>

<script>
export default {
	name: 'LINK_PLANNED',
};
</script>

<style scoped></style>
