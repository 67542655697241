<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M11.591 8.40901C12.4697 9.2877 12.4697 10.7123 11.591 11.591C10.7123 12.4697 9.2877 12.4697 8.40901 11.591C7.53033 10.7123 7.53033 9.2877 8.40901 8.40901C9.2877 7.53033 10.7123 7.53033 11.591 8.40901"
			stroke="#8F20EF"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M4.37499 10C4.37499 10.2475 4.39749 10.495 4.42749 10.735L3.10416 11.77C2.81083 12 2.73083 12.4108 2.91749 12.7333L4.09416 14.7692C4.27999 15.0917 4.67499 15.2275 5.02083 15.0892L6.20583 14.6133C6.43999 14.5192 6.70083 14.5567 6.91083 14.6958C7.09416 14.8175 7.28416 14.9292 7.48083 15.0292C7.70583 15.1433 7.86916 15.3475 7.90499 15.5975L8.08583 16.8583C8.13833 17.2267 8.45416 17.5 8.82583 17.5H11.1733C11.545 17.5 11.8608 17.2267 11.9133 16.8583L12.0942 15.5983C12.13 15.3483 12.295 15.1425 12.5208 15.0292C12.7167 14.9308 12.9058 14.82 13.0883 14.6992C13.3 14.5592 13.5617 14.5192 13.7967 14.6142L14.9792 15.0892C15.3242 15.2275 15.7192 15.0917 15.9058 14.7692L17.0825 12.7333C17.2692 12.4108 17.1892 11.9992 16.8958 11.77L15.5725 10.735C15.6025 10.495 15.625 10.2475 15.625 10C15.625 9.7525 15.6025 9.505 15.5725 9.265L16.8958 8.23C17.1892 8 17.2692 7.58917 17.0825 7.26667L15.9058 5.23083C15.72 4.90833 15.325 4.7725 14.9792 4.91083L13.7967 5.38583C13.5617 5.48 13.3 5.44083 13.0883 5.30083C12.9058 5.18 12.7167 5.06917 12.5208 4.97083C12.295 4.8575 12.13 4.65167 12.0942 4.40167L11.9142 3.14167C11.8617 2.77333 11.5458 2.5 11.1742 2.5H8.82666C8.45499 2.5 8.13916 2.77333 8.08666 3.14167L7.90499 4.40333C7.86916 4.6525 7.70499 4.8575 7.48083 4.97167C7.28416 5.07167 7.09416 5.18417 6.91083 5.305C6.69999 5.44333 6.43916 5.48083 6.20499 5.38667L5.02083 4.91083C4.67499 4.7725 4.27999 4.90833 4.09416 5.23083L2.91749 7.26667C2.73083 7.58917 2.81083 8.00083 3.10416 8.23L4.42749 9.265C4.39749 9.505 4.37499 9.7525 4.37499 10V10Z"
			stroke="#8F20EF"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'profilerOperations',
};
</script>

<style scoped></style>
