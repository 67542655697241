<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M7.74375 3.23775C8.06325 1.92075 9.93675 1.92075 10.2563 3.23775C10.3042 3.4356 10.3982 3.61933 10.5305 3.774C10.6629 3.92867 10.8299 4.04989 11.018 4.12781C11.2061 4.20573 11.4099 4.23814 11.6128 4.2224C11.8158 4.20667 12.0122 4.14323 12.186 4.03725C13.3432 3.33225 14.6685 4.65675 13.9635 5.81475C13.8577 5.98849 13.7943 6.18475 13.7786 6.38758C13.7629 6.59041 13.7953 6.79408 13.8731 6.98203C13.951 7.16999 14.0721 7.33693 14.2265 7.46929C14.381 7.60164 14.5646 7.69568 14.7623 7.74375C16.0793 8.06325 16.0793 9.93675 14.7623 10.2563C14.5644 10.3042 14.3807 10.3982 14.226 10.5305C14.0713 10.6629 13.9501 10.8299 13.8722 11.018C13.7943 11.2061 13.7619 11.4099 13.7776 11.6128C13.7933 11.8158 13.8568 12.0122 13.9628 12.186C14.6678 13.3432 13.3432 14.6685 12.1852 13.9635C12.0115 13.8577 11.8152 13.7943 11.6124 13.7786C11.4096 13.7629 11.2059 13.7953 11.018 13.8731C10.83 13.951 10.6631 14.0721 10.5307 14.2265C10.3984 14.381 10.3043 14.5646 10.2563 14.7623C9.93675 16.0793 8.06325 16.0793 7.74375 14.7623C7.69581 14.5644 7.60183 14.3807 7.46947 14.226C7.3371 14.0713 7.17008 13.9501 6.98201 13.8722C6.79394 13.7943 6.59013 13.7619 6.38716 13.7776C6.1842 13.7933 5.98781 13.8568 5.814 13.9628C4.65675 14.6678 3.3315 13.3432 4.0365 12.1852C4.14233 12.0115 4.20566 11.8152 4.22136 11.6124C4.23706 11.4096 4.20468 11.2059 4.12685 11.018C4.04903 10.83 3.92795 10.6631 3.77345 10.5307C3.61896 10.3984 3.43542 10.3043 3.23775 10.2563C1.92075 9.93675 1.92075 8.06325 3.23775 7.74375C3.4356 7.69581 3.61933 7.60183 3.774 7.46947C3.92867 7.3371 4.04989 7.17008 4.12781 6.98201C4.20573 6.79394 4.23814 6.59013 4.2224 6.38716C4.20667 6.1842 4.14323 5.98781 4.03725 5.814C3.33225 4.65675 4.65675 3.3315 5.81475 4.0365C6.56475 4.4925 7.53675 4.089 7.74375 3.23775Z" stroke="#A9A9AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M6.75 9C6.75 9.59674 6.98705 10.169 7.40901 10.591C7.83097 11.0129 8.40326 11.25 9 11.25C9.59674 11.25 10.169 11.0129 10.591 10.591C11.0129 10.169 11.25 9.59674 11.25 9C11.25 8.40326 11.0129 7.83097 10.591 7.40901C10.169 6.98705 9.59674 6.75 9 6.75C8.40326 6.75 7.83097 6.98705 7.40901 7.40901C6.98705 7.83097 6.75 8.40326 6.75 9Z" stroke="#A9A9AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
</template>

<script>
export default {
	name: 'menuMaintenance',
};
</script>

<style scoped></style>
