<template>
	<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle
			cx="16.4993"
			cy="16.4994"
			r="11.2547"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M30.7552 30.7553L24.457 24.4572"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path d="M11.3687 13.7232H21.5459" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M11.3687 19.2755H18.5732" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'KeywordsReport',
};
</script>

<style scoped></style>
