<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M4.16683 10H15.8335" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M8.3335 14.1667L4.16683 10" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M8.3335 5.83334L4.16683 10" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'arrowLeft',
};
</script>

<style scoped></style>
