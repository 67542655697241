<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M6.66663 6.6665L13.3333 13.3332"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M13.3333 6.6665L6.66663 13.3332"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'close',
};
</script>

<style scoped></style>
