<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M2.49988 13.3337L6.66655 8.88866L10.8332 13.3337L17.4999 6.66699"
			stroke="#2A355A"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'brokenLine',
};
</script>

<style scoped></style>
