<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M1.16666 15.8333C1.16666 16.1095 1.39051 16.3333 1.66666 16.3333C1.9428 16.3333 2.16666 16.1095 2.16666 15.8333H1.16666ZM11.1667 15.8333C11.1667 16.1095 11.3905 16.3333 11.6667 16.3333C11.9428 16.3333 12.1667 16.1095 12.1667 15.8333H11.1667ZM2.16666 15.8333C2.16666 14.2761 3.4428 13 4.99999 13V12C2.89051 12 1.16666 13.7239 1.16666 15.8333H2.16666ZM4.99999 13H8.33332V12H4.99999V13ZM8.33332 13C9.89051 13 11.1667 14.2761 11.1667 15.8333H12.1667C12.1667 13.7239 10.4428 12 8.33332 12V13Z"
			fill="#8F20EF" />
		<path
			d="M8.74998 5.00004C9.91665 6.16671 9.91665 8.00004 8.74998 9.08338C7.58331 10.1667 5.74998 10.25 4.66665 9.08338C3.58331 7.91671 3.49998 6.16671 4.58331 5.00004C5.66665 3.83338 7.58331 3.91671 8.74998 5.00004"
			stroke="#8F20EF"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M13.3333 11.1667C13.0572 11.1667 12.8333 11.3906 12.8333 11.6667C12.8333 11.9429 13.0572 12.1667 13.3333 12.1667V11.1667ZM17.8333 14.1667C17.8333 14.4429 18.0572 14.6667 18.3333 14.6667C18.6095 14.6667 18.8333 14.4429 18.8333 14.1667H17.8333ZM13.3333 12.1667H15.8333V11.1667H13.3333V12.1667ZM15.8333 12.1667C16.9738 12.1667 17.8333 13.0262 17.8333 14.1667H18.8333C18.8333 12.4739 17.5261 11.1667 15.8333 11.1667V12.1667Z"
			fill="#8F20EF" />
		<path
			d="M16.0833 5.5835C16.9167 6.41683 16.9167 7.75016 16.0833 8.50016C15.25 9.25016 13.9167 9.3335 13.1667 8.50016C12.4167 7.66683 12.3333 6.3335 13.1667 5.5835C13.9167 4.8335 15.25 4.8335 16.0833 5.5835"
			stroke="#8F20EF"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'copywriters',
};
</script>

<style scoped></style>
