<template>
	<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M8.90077 17.4534C9.1467 17.4209 9.39514 17.4876 9.59106 17.6385L10.4939 18.3313C10.7924 18.5606 11.2076 18.5606 11.5052 18.3313L12.4422 17.6118C12.6173 17.4776 12.8382 17.4184 13.0567 17.4476L14.2288 17.6018C14.6015 17.651 14.9608 17.4434 15.105 17.0958L15.556 16.0053C15.6402 15.8011 15.802 15.6393 16.0062 15.5551L17.0967 15.1041C17.4443 14.9607 17.6519 14.6006 17.6027 14.2279L17.4543 13.0983C17.4218 12.8524 17.4885 12.6039 17.6394 12.408L18.3322 11.5051C18.5615 11.2067 18.5615 10.7915 18.3322 10.4939L17.6127 9.55681C17.4785 9.38174 17.4193 9.16082 17.4485 8.94239L17.6027 7.77024C17.6519 7.39758 17.4443 7.03827 17.0967 6.89404L16.0062 6.44302C15.802 6.35882 15.6402 6.19708 15.556 5.99283L15.105 4.90238C14.9616 4.55473 14.6015 4.34714 14.2288 4.39633L13.0567 4.55056C12.8382 4.58058 12.6173 4.52138 12.4431 4.38799L11.506 3.66853C11.2076 3.43927 10.7924 3.43927 10.4948 3.66853L9.55771 4.38799C9.38264 4.52138 9.16171 4.58058 8.94329 4.55223L7.77113 4.398C7.39848 4.34881 7.03916 4.5564 6.89493 4.90404L6.44475 5.9945C6.35971 6.19792 6.19798 6.35965 5.99456 6.44469L4.9041 6.89487C4.55646 7.0391 4.34887 7.39842 4.39806 7.77107L4.55229 8.94323C4.58064 9.16165 4.52144 9.38258 4.38806 9.55681L3.66859 10.4939C3.43933 10.7923 3.43933 11.2075 3.66859 11.5051L4.38806 12.4422C4.52228 12.6173 4.58147 12.8382 4.55229 13.0566L4.39806 14.2288C4.34887 14.6014 4.55646 14.9607 4.9041 15.105L5.99456 15.556C6.19881 15.6402 6.36054 15.8019 6.44475 16.0062L6.89577 17.0966C7.03916 17.4443 7.39931 17.6519 7.77197 17.6027L8.90077 17.4534Z"
			fill="#24C182" />
		<path
			d="M13.3334 9.8335L10.4147 12.7522L8.66483 11.0015"
			stroke="white"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'approvedUser',
};
</script>

<style scoped></style>
