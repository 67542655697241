<template>
	<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M18.0002 10.7998V25.1998" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M25.1998 18.0002H10.7998" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
</template>
<script>
export default {
	name: 'Plus',
};
</script>

<style scoped></style>
