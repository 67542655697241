<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M15.8333 15.8158L17.5 14.1491L15.8333 12.4824" stroke-linecap="round" stroke-linejoin="round" />
		<path
			d="M8.485 7.49985L7.16833 5.68985C6.85333 5.25652 6.34917 5.00152 5.81333 5.00318L2.5 5.01735"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path d="M15.8333 6.66659L17.5 4.99992L15.8333 3.33325" stroke-linecap="round" stroke-linejoin="round" />
		<path
			d="M11.515 11.6665L12.8316 13.4765C13.1466 13.9098 13.6508 14.1648 14.1866 14.1632L17.5 14.149"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M17.5 5.01736L14.1867 5.00319C13.6508 5.00069 13.1467 5.25653 12.8317 5.68986L7.16833 13.4765C6.85333 13.9099 6.34917 14.1649 5.81333 14.1632L2.5 14.149"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'PAGE_REDIRECTED',
};
</script>

<style scoped></style>
