<template>
	<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M11.9975 5.995L9.74658 8.24593"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M9.74658 3.74408L11.9975 5.99502"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M8.99626 5.995C6.50994 5.995 4.49438 8.01055 4.49438 10.4969"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path d="M8.99609 5.995H11.9973" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
		<path
			d="M24.0024 30.005L26.2534 27.754"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M26.2534 32.2559L24.0024 30.005"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M27.0037 30.005C29.49 30.005 31.5055 27.9894 31.5055 25.5031"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path d="M27.0037 30.005H24.0024" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M14.2483 30.005C18.8066 30.005 22.5017 26.3098 22.5017 21.7515C22.5017 17.1933 18.8066 13.4981 14.2483 13.4981C9.69006 13.4981 5.99487 17.1933 5.99487 21.7515C6.0211 26.2989 9.70097 29.9788 14.2483 30.005Z"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M16.5833 20.5856L13.6661 23.5028L11.9133 21.7516"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M27.5874 8.41248C30.7972 11.6411 30.7972 16.8557 27.5874 20.0843C27.3315 20.3315 27.0571 20.5587 26.7666 20.7641"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M27.5874 8.41248C24.3588 5.20268 19.1441 5.20268 15.9156 8.41248C15.6682 8.6676 15.4386 8.93935 15.2283 9.22582"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'RlTop',
};
</script>

<style scoped></style>
