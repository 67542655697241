<template>
	<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="Group">
			<g id="Group_2">
				<path
					id="Path"
					d="M20.4828 15.517C21.8541 16.8883 21.8541 19.1117 20.4828 20.483C19.1115 21.8544 16.8881 21.8544 15.5168 20.483C14.1454 19.1117 14.1454 16.8883 15.5168 15.517C16.8881 14.1457 19.1115 14.1457 20.4828 15.517"
					stroke="#8591AE"
					stroke-linecap="round"
					stroke-linejoin="round" />
				<path
					id="Path_2"
					d="M32.1731 15.7652C33.4074 16.9994 33.4074 19.0005 32.1731 20.2348C30.9389 21.469 28.9378 21.469 27.7035 20.2348C26.4693 19.0005 26.4693 16.9994 27.7035 15.7652C28.9378 14.5309 30.9389 14.5309 32.1731 15.7652"
					stroke="#8591AE"
					stroke-linecap="round"
					stroke-linejoin="round" />
				<path
					id="Path_3"
					d="M8.29619 15.7652C9.53045 16.9994 9.53045 19.0005 8.29619 20.2348C7.06194 21.469 5.06083 21.469 3.82657 20.2348C2.59232 19.0005 2.59232 16.9994 3.82657 15.7652C5.06082 14.5309 7.06193 14.5309 8.29619 15.7652"
					stroke="#8591AE"
					stroke-linecap="round"
					stroke-linejoin="round" />
				<path
					id="Path_4"
					d="M26.2032 5.42569C27.4374 6.65994 27.4374 8.66105 26.2032 9.89531C24.9689 11.1296 22.9678 11.1296 21.7336 9.89531C20.4993 8.66106 20.4993 6.65995 21.7336 5.42569C22.9678 4.19144 24.9689 4.19144 26.2032 5.42569"
					stroke="#8591AE"
					stroke-linecap="round"
					stroke-linejoin="round" />
				<path
					id="Path_5"
					d="M14.2662 26.1046C15.5004 27.3389 15.5004 29.34 14.2662 30.5743C13.0319 31.8085 11.0308 31.8085 9.79654 30.5743C8.56229 29.34 8.56229 27.3389 9.79654 26.1046C11.0308 24.8704 13.0319 24.8704 14.2662 26.1046"
					stroke="#8591AE"
					stroke-linecap="round"
					stroke-linejoin="round" />
				<path
					id="Path_6"
					d="M14.2662 5.42569C15.5004 6.65994 15.5004 8.66105 14.2662 9.89531C13.0319 11.1296 11.0308 11.1296 9.79654 9.89531C8.56229 8.66106 8.56229 6.65995 9.79654 5.42569C11.0308 4.19144 13.0319 4.19144 14.2662 5.42569"
					stroke="#8591AE"
					stroke-linecap="round"
					stroke-linejoin="round" />
				<path
					id="Path_7"
					d="M26.2032 26.1046C27.4374 27.3389 27.4374 29.34 26.2032 30.5743C24.9689 31.8085 22.9678 31.8085 21.7336 30.5743C20.4993 29.34 20.4993 27.3389 21.7336 26.1046C22.9678 24.8704 24.9689 24.8704 26.2032 26.1046"
					stroke="#8591AE"
					stroke-linecap="round"
					stroke-linejoin="round" />
				<path
					id="Path_8"
					d="M13.605 25.605L16.245 21.03"
					stroke="#8591AE"
					stroke-linecap="round"
					stroke-linejoin="round" />
				<path
					id="Path_9"
					d="M13.605 10.395L16.245 14.97"
					stroke="#8591AE"
					stroke-linecap="round"
					stroke-linejoin="round" />
				<path
					id="Path_10"
					d="M26.7748 18H21.5098"
					stroke="#8591AE"
					stroke-linecap="round"
					stroke-linejoin="round" />
			</g>
		</g>
	</svg>
</template>

<script>
export default {
	name: 'PBNPerformance',
};
</script>

<style scoped></style>
