<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9.99916 6.66665C9.88416 6.66665 9.79083 6.75998 9.79166 6.87498C9.79166 6.98998 9.885 7.08331 10 7.08331C10.115 7.08331 10.2083 6.98998 10.2083 6.87498C10.2083 6.75998 10.115 6.66665 9.99916 6.66665"
			stroke="#8F20EF"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M10 17.5V17.5C5.8575 17.5 2.5 14.1425 2.5 10V10C2.5 5.8575 5.8575 2.5 10 2.5V2.5C14.1425 2.5 17.5 5.8575 17.5 10V10C17.5 14.1425 14.1425 17.5 10 17.5Z"
			stroke="#8F20EF"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path d="M9.99999 10V14.1667" stroke="#8F20EF" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'overview',
};
</script>

<style scoped></style>
