<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M16.6668 5.41669L7.50016 14.5834L3.3335 10.4167"
			stroke="white"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'save',
};
</script>

<style scoped></style>
