<template>
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="32" height="32" rx="8" fill="#FFF8F2" />
		<path
			d="M17.1943 8.55583C20.7401 9.15167 23.4443 12.2283 23.4443 15.9433C23.4443 20.0858 20.0868 23.4433 15.9443 23.4433C12.2293 23.4433 9.1526 20.7392 8.55676 17.1933"
			stroke="#FF974A"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M8.55587 14.6942C8.63504 14.2233 8.75754 13.7692 8.92004 13.3325"
			stroke="#FF974A"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M11.1642 10.165C10.8 10.4658 10.4659 10.8 10.165 11.1642"
			stroke="#FF974A"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M14.6942 8.55584C14.2234 8.635 13.7684 8.75834 13.3325 8.92084"
			stroke="#FF974A"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M16.3259 11.8333V16.3258H12.6667"
			stroke="#FF974A"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'CPworks',
};
</script>

<style scoped></style>
