<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M15.002 8.33235V6.52159C15.002 6.07974 14.8261 5.6554 14.5135 5.34277L12.1558 2.98512C11.8432 2.67249 11.4189 2.49658 10.977 2.49658H4.99787C4.07665 2.49658 3.33051 3.24273 3.33051 4.16394V15.8355C3.33051 16.7567 4.07665 17.5028 4.99787 17.5028H8.33259"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M15.002 6.66499H11.6673C11.2071 6.66499 10.8336 6.2915 10.8336 5.8313V2.49658"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M13.7516 18.3366C11.6799 18.3366 10 16.6568 10 14.5851C10 12.5134 11.6799 10.8335 13.7516 10.8335C15.8241 10.8335 17.5032 12.5134 17.5032 14.5851C17.5032 16.6568 15.8241 18.3366 13.7516 18.3366"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path d="M13.7515 13.0884V16.0805" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M15.2479 14.5848H12.2558" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'CONTENT_NEW_PAGE',
};
</script>

<style scoped></style>
