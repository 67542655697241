<template>
	<svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M4.75 12H31.75" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M8.5 24V16.5H11" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
		<path
			d="M11 16.5C12.3805 16.5 13.5 17.4324 13.5 18.5833C13.5 19.7343 12.3805 20.6667 11 20.6667"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path d="M11.1485 20.6667H8.7334" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M13.5 23.9998L11 20.6665" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
		<path
			d="M21.416 21.5C21.416 22.8805 20.2971 24 18.916 24C17.5349 24 16.416 22.8805 16.416 21.5"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path d="M16.4163 21.5L16.4163 16.5" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M21.4163 21.5L21.4163 16.5" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M27.25 28.5H9.25C6.7645 28.5 4.75 26.4855 4.75 24V12C4.75 9.5145 6.7645 7.5 9.25 7.5H27.25C29.7355 7.5 31.75 9.5145 31.75 12V24C31.75 26.4855 29.7355 28.5 27.25 28.5Z"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'CardRus',
};
</script>

<style scoped></style>
