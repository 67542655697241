<template>
	<svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M19 32.5056V32.5056C11.5411 32.5056 5.49438 26.459 5.49438 19V19C5.49438 11.5411 11.5411 5.49438 19 5.49438V5.49438C26.459 5.49438 32.5056 11.5411 32.5056 19V19C32.5056 26.459 26.459 32.5056 19 32.5056V32.5056Z"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M16.4983 12.4978V12.4978C16.4983 11.1162 17.6183 9.99622 18.9998 9.99622V9.99622C20.3814 9.99622 21.5014 11.1162 21.5014 12.4978V12.4978C21.5014 13.8793 20.3814 14.9993 18.9998 14.9993V14.9993C17.6186 14.9985 16.4991 13.879 16.4983 12.4978Z"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M10.4358 22.5009V22.5009C10.4358 21.1194 11.5558 19.9994 12.9373 19.9994V19.9994C14.3189 19.9994 15.4389 21.1194 15.4389 22.5009V22.5009C15.438 23.8822 14.3186 25.0016 12.9373 25.0025V25.0025C11.5558 25.0025 10.4358 23.8825 10.4358 22.5009V22.5009Z"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M22.561 22.5009V22.5009C22.561 21.1194 23.681 19.9994 25.0626 19.9994V19.9994C26.4441 19.9994 27.5641 21.1194 27.5641 22.5009V22.5009C27.5641 23.8825 26.4441 25.0025 25.0626 25.0025V25.0025C23.681 25.0025 22.561 23.8825 22.561 22.5009V22.5009Z"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M20.3506 14.6031L23.9806 20.244"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M17.6403 14.6031L14.0283 20.244"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path d="M22.5564 22.4965H15.439" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'Crossmap',
};
</script>

<style scoped></style>
