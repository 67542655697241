<template>
	<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="Group">
			<g id="Group_2">
				<path id="Path" d="M12 19.5H24" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
				<path id="Path_2" d="M12 25.5H24" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
				<path
					id="Path_3"
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M9 4.5H22.758C23.553 4.5 24.3165 4.8165 24.879 5.379L29.121 9.621C29.6835 10.1835 30 10.947 30 11.742V28.5C30 30.1575 28.6575 31.5 27 31.5H9C7.3425 31.5 6 30.1575 6 28.5V7.5C6 5.8425 7.3425 4.5 9 4.5Z"
					stroke="#8591AE"
					stroke-linecap="round"
					stroke-linejoin="round" />
				<path
					id="Path_4"
					d="M30 12H24C23.172 12 22.5 11.328 22.5 10.5V4.5"
					stroke="#8591AE"
					stroke-linecap="round"
					stroke-linejoin="round" />
				<path id="Path_5" d="M12 13.5H16.5" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
			</g>
		</g>
	</svg>
</template>

<script>
export default {
	name: 'ProjectContent',
};
</script>

<style scoped></style>
