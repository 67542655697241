<template>
	<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12 6L13.95 3.5625C14.235 3.207 14.6655 3 15.1215 3H20.88C21.336 3 21.7665 3.207 22.0515 3.5625L24 6V6V7.5C24 8.328 23.328 9 22.5 9H13.5C12.672 9 12 8.328 12 7.5V6V6H12Z"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M14.5005 13.9995L12 16.5L10.5 15"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path d="M19.5 27H24" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M19.5 15H24" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
		<path
			d="M14.5005 19.9995L12 22.5L10.5 21"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path d="M19.5 21H24" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
		<path
			d="M24 6H27C28.6575 6 30 7.3425 30 9V30C30 31.6575 28.6575 33 27 33H9C7.3425 33 6 31.6575 6 30V9C6 7.3425 7.3425 6 9 6H12"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'ContentAudit',
};
</script>

<style scoped></style>
