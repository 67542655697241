<template>
	<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M30.0049 18V11.7394C30.0049 10.9435 29.6884 10.1802 29.1255 9.61752L24.8817 5.37375C24.319 4.81087 23.5558 4.49438 22.7599 4.49438H8.99612C7.33858 4.49438 5.99487 5.83809 5.99487 7.49563V28.5044C5.99487 30.1619 7.33858 31.5056 8.99612 31.5056H11.9974"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<rect
			x="16.4993"
			y="25.8783"
			width="4.50187"
			height="5.62734"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<rect
			x="21.0012"
			y="19.5006"
			width="4.50187"
			height="12.005"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<rect
			x="25.5032"
			y="22.877"
			width="4.50187"
			height="8.62859"
			stroke="#8591AE"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path d="M11.9973 11.9975H22.5017" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M11.9973 17.2497H15.7489" stroke="#8591AE" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'PassportReport',
};
</script>

<style scoped></style>
