<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M2.80287 15.8365H5.45438V9.39712L1.6665 6.55621V14.7002C1.6665 15.3289 2.17597 15.8365 2.80287 15.8365Z"
			fill="#4285F4" />
		<path
			d="M14.5454 15.8365H17.1969C17.8257 15.8365 18.3333 15.327 18.3333 14.7002V6.55621L14.5454 9.39712"
			fill="#34A853" />
		<path
			d="M14.5454 4.4729V9.39714L18.3333 6.55623V5.04108C18.3333 3.63578 16.7291 2.83464 15.606 3.67745"
			fill="#FBBC04" />
		<path d="M5.45459 9.39714V4.4729L10 7.88199L14.5455 4.4729V9.39714L10 12.8062" fill="#EA4335" />
		<path
			d="M1.6665 5.04108V6.55623L5.45438 9.39714V4.4729L4.39378 3.67745C3.26878 2.83464 1.6665 3.63578 1.6665 5.04108Z"
			fill="#C5221F" />
	</svg>
</template>

<script>
export default {
	name: 'gmail',
};
</script>

<style scoped></style>
