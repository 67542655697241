<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M8.75 5C5.29833 5 2.5 7.79833 2.5 11.25C2.5 14.7017 5.29833 17.5 8.75 17.5C12.2017 17.5 15 14.7017 15 11.25L8.75 11.25V5Z"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M17.5 8.33333C17.5 5.11167 14.8883 2.5 11.6666 2.5L11.6666 8.33333H17.5Z"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'menuMyDashboard',
};
</script>

<style scoped></style>
