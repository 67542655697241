<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M10 2.5C14.1517 2.5 17.5 5.84833 17.5 10C17.5 14.1517 14.1517 17.5 10 17.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M10 17.5C5.84833 17.5 2.5 14.1517 2.5 10C2.5 5.84833 5.84833 2.5 10 2.5"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M8.4733 3.38337C6.06497 7.4042 6.06497 12.5967 8.4733 16.6175C9.1783 17.795 10.8225 17.795 11.5275 16.6175C13.9358 12.5967 13.9358 7.4042 11.5275 3.38337C10.8216 2.20587 9.1783 2.20587 8.4733 3.38337Z"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M3.34167 6.54834C7.41834 7.80751 12.5817 7.80751 16.6575 6.54834"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M16.6584 13.4517C12.5817 12.1925 7.41836 12.1925 3.34253 13.4517"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'global',
};
</script>

<style scoped></style>
