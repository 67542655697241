<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M14.125 10.5891L16.4817 8.23241C17.7833 6.93074 17.7833 4.81991 16.4817 3.51824V3.51824C15.18 2.21658 13.0692 2.21658 11.7675 3.51824L9.41083 5.87491"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M5.87494 9.41064L3.51827 11.7673C2.21661 13.069 2.21661 15.1798 3.51827 16.4815V16.4815C4.81994 17.7831 6.93077 17.7831 8.23244 16.4815L10.5891 14.1248"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path d="M4.70008 7.05013L2.93341 6.4668" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M7.04995 4.69977L6.46661 2.93311" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M15.2999 12.9502L17.0749 13.5335" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M12.9501 15.2998L13.5334 17.0748" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M7.64169 12.3583L12.3584 7.6416" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'LINK_DELETED',
};
</script>

<style scoped></style>
