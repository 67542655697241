<template>
	<v-icon v-if="multisort" size="20">{{ prop ? prop : '' }}</v-icon>
	<v-icon v-else size="20">
		{{
			!options?.sortBy?.length
				? '$tableSort'
				: options?.sortBy?.[0]?.key === value && options?.sortBy?.[0]?.order === 'desc'
					? '$tableSortDown'
					: options?.sortBy?.[0]?.key === value && options?.sortBy?.[0]?.order === 'asc'
						? '$tableSortUp'
						: '$tableSort'
		}}
	</v-icon>
</template>
<script>
export default {
	name: 'SortFIcon',
	props: ['options', 'value', 'multisort'],
	computed: {
		prop() {
			const sortObj = this.options.sortBy.find(item => item.key === this.value);
			if (sortObj) {
				return sortObj.order === 'desc' ? '$tableSortDown' : '$tableSortUp';
			}
			return '$tableSort';
		}
	},
};
</script>
<style scoped></style>
