<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M13.2241 7.60918C14.2024 6.63084 15.7874 6.63084 16.7658 7.60918V7.60918C17.7441 8.58751 17.7441 10.1725 16.7658 11.1508L12.6016 15.315C11.6233 16.2933 10.0383 16.2933 9.05992 15.315V15.315C8.08159 14.3367 8.08159 12.7517 9.05992 11.7733L9.68242 11.1508"
			stroke="#FC5A5A"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M7.60916 16.7667C6.63082 17.745 5.04582 17.745 4.06749 16.7667V16.7667C3.08916 15.7883 3.08916 14.2033 4.06749 13.225L8.23166 9.06084C9.20999 8.0825 10.795 8.0825 11.7733 9.06084V9.06084C12.7517 10.0392 12.7517 11.6242 11.7733 12.6025L11.125 13.25"
			stroke="#FC5A5A"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path d="M3.33331 4.58335H7.49998" stroke="#FC5A5A" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M5.41667 6.66667V2.5" stroke="#FC5A5A" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'backlinks',
};
</script>

<style scoped></style>
