<template>
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="32" height="32" rx="8" fill="#F1FCF8" />
		<path
			d="M16 8.5C20.1517 8.5 23.5 11.8483 23.5 16C23.5 20.1517 20.1517 23.5 16 23.5"
			stroke="#24C182"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M16 23.5C11.8483 23.5 8.5 20.1517 8.5 16C8.5 11.8483 11.8483 8.5 16 8.5"
			stroke="#24C182"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M14.4733 9.38313C12.065 13.404 12.065 18.5965 14.4733 22.6173C15.1783 23.7948 16.8225 23.7948 17.5275 22.6173C19.9358 18.5965 19.9358 13.404 17.5275 9.38313C16.8216 8.20562 15.1783 8.20562 14.4733 9.38313Z"
			stroke="#24C182"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M9.34167 12.5479C13.4183 13.807 18.5817 13.807 22.6575 12.5479"
			stroke="#24C182"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M22.6584 19.4512C18.5817 18.192 13.4184 18.192 9.34253 19.4512"
			stroke="#24C182"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: 'LBOB2',
};
</script>

<style scoped></style>
